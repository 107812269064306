import React, {useContext,useEffect } from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion, useScroll, useSpring } from "framer-motion"
import Footer from "../components/Layouts/Footer";
import DownloadSection from '../components/Layouts/Home/DownloadSection';
import HomeNavbar from '../components/Layouts/Home/HomeNavbar';
import { decryptData } from '../utils/DecryptLocalStorage';
import { setAlphaUser } from '../Actions/Auth/authSlice';
import { setCartId } from '../Actions/Cart/cartSlice';
import { config } from '../config';
import AnimatedRoutes from './AnimatedRoutes';
import { actionTypes } from '../AppContexts/constants';
import { AppContext } from '../AppContexts/AppContext';

const Routing = () => {
     const { scrollYProgress } = useScroll();
     const scaleX = useSpring(scrollYProgress, {
          stiffness: 100,
          damping: 30,
          restDelta: 0.001
     });
     const dispatch = useDispatch();
     const {uploadDispatch, editIndex} = useContext(AppContext)
     const getUser = localStorage.getItem("alpha");
     const getBasket = localStorage.getItem("basket");

     const secret = config.key;

     const {first_name, last_name, email, phone, _id} = useSelector(state => state.auth);


     const user = getUser !== null && decryptData(getUser, secret);

     const cartId = getBasket !== null && decryptData(getBasket,secret);
     

     useEffect(() => {
         if(user){
             dispatch(setAlphaUser(user));
         }
     }, [user, dispatch]);
     useEffect(() => {
        if(cartId){
          dispatch(setCartId(cartId))
        }
     }, [cartId, dispatch])

     useEffect(() => {
          if (_id || editIndex !== null) {
            uploadDispatch({ type: actionTypes.SET_USERID, payload: _id });
          }
          if (email || editIndex !== null) {
            uploadDispatch({ type: actionTypes.SET_MERCHANT_EMAIL, payload: email });
          }
          if (first_name || editIndex !== null) {
            uploadDispatch({ type: actionTypes.SET_MERCHANT_FIRSTNAME, payload: first_name });
          }
          if (last_name || editIndex !== null) {
            uploadDispatch({ type: actionTypes.SET_MERCHANT_LASTNAME, payload: last_name });
          }
          if (phone || editIndex !== null) {
            uploadDispatch({ type: actionTypes.SET_MERCHANT_PHONE, payload: phone });
          }
        }, [_id, email, first_name, last_name, phone, uploadDispatch, editIndex]);

     return (
          <Router>
               <motion.div className="progress-bar" style={{ scaleX }} />
               <HomeNavbar />
               <main className="showcase">
                  <AnimatedRoutes />
               </main>  
               <DownloadSection />
               <Footer />
              
         </Router>
     )
}

export default Routing;